<template>
    <div class="d-flex flex-column min-vh-100">
        <Header :showLogout="true" :profile="profile"/>
        <div class="container">
            <div class="row">
                <div class="col-3">
                    <div class="list-group">
                        <router-link to="/profile" class="list-group-item active"><i class="fa fa-book"></i> <span>Mis cursos</span></router-link>
                        <router-link to="/profile/payments" class="list-group-item"><i class="fa fa-credit-card"></i> <span>Pagos</span></router-link>
                        <router-link to="/profile/me" class="list-group-item"><i class="fa fa-key"></i> <span>Mis datos</span></router-link>
                    </div>
                </div>
                <div class="col-7">
                    <h2>Mis Cursos</h2>
                    <div class="">
                        <div class="card mt-4" v-for="course in courses" :key="course.id">
                            <div class="card-horizontal">
                                <div class="img-square-wrapper">
                                    <img class="" :src="`/storage/courses/${course.cover}`" alt="Card image cap" style="width:200px">
                                </div>
                                <div class="card-body">
                                    <h4 class="card-title">{{course.name}}</h4>
                                    <p class="card-text">Inscrito el {{course.boughtOn}}</p>
                                    <a class="btn btn-sm btn-info" :href="profile.lms_magiclink">Ir al curso</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>
<style scoped>
 .list-group {

     margin:auto;
     float:left;
     padding-top:20px;
    }
    .card-horizontal {
    display: flex;
    flex: 1 1 auto;
}
</style>
<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import axios from 'axios';

export default {
  components:{
      Header,
      Footer
  },
  name: 'Profile',
  data() {
      return {
          courses: [],
          profile: this.$store.getters.getUser
      }
  },
  mounted(){
      this.loadCourses();
  },
  methods: {
      loadCourses() {
          axios.get(process.env.VUE_APP_APIURL + 'users/paidinvoices/' + this.profile.id)
          .then((response)=>{
              //alert(JSON.stringify(response.data));
              this.courses =  response.data.data
          }).catch((error)=>{
              alert(error);
          })
      }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bg-darkBlue{
    background: #152F44
}
.btn-black{ background:#000; color:#FFF; font-size:14px; border:1px solid #979797;}
.btn-black:hover{color:#FFF; background:#333232}
.btn-golden{ background:#AA8D5D; color:#FFF; font-size:14px; border:1px solid #9B7676;}
.btn-golden:hover{color:#FFF; background:#C4A167}

.navbar-light .navbar-nav .nav-link{ color:#FFF}
.navbar-light .navbar-nav .nav-link:hover{ background:#C4A167}
.btn-outline-white{
    border:1px solid #FFF;
    color:#FFF
}
.carousel-caption {top:30%}
.carousel-caption h5{ margin-bottom:30px}
.section{margin:60px 0}
footer{margin-top:60px}
.subheader{ background:#D1C1A7; color:#FFF; display: flex;
  align-items: center;
  justify-content: center; height:250px}
  .course:hover{
      opacity:.8
  }
</style>
